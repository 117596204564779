import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './generateScheduledPayments.graphql';

const defaultOptions = {} as const;
export type GenerateScheduledPaymentsMutationVariables = Types.Exact<{
	now: Types.Scalars['DateTime'];
}>;

export type GenerateScheduledPaymentsMutation = {generateSchedPayment: boolean};

export type GenerateScheduledPaymentsType = boolean;

export type GenerateScheduledPaymentsMutationFn = Apollo.MutationFunction<
	GenerateScheduledPaymentsMutation,
	GenerateScheduledPaymentsMutationVariables
>;

/**
 * __useGenerateScheduledPaymentsMutation__
 *
 * To run a mutation, you first call `useGenerateScheduledPaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateScheduledPaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateScheduledPaymentsMutation, { data, loading, error }] = useGenerateScheduledPaymentsMutation({
 *   variables: {
 *      now: // value for 'now'
 *   },
 * });
 */
export function useGenerateScheduledPaymentsMutation(
	baseOptions?: Apollo.MutationHookOptions<GenerateScheduledPaymentsMutation, GenerateScheduledPaymentsMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<GenerateScheduledPaymentsMutation, GenerateScheduledPaymentsMutationVariables>(Operations, options);
}
export type GenerateScheduledPaymentsMutationHookResult = ReturnType<typeof useGenerateScheduledPaymentsMutation>;
export type GenerateScheduledPaymentsMutationResult = Apollo.MutationResult<GenerateScheduledPaymentsMutation>;
export type GenerateScheduledPaymentsMutationOptions = Apollo.BaseMutationOptions<
	GenerateScheduledPaymentsMutation,
	GenerateScheduledPaymentsMutationVariables
>;
