import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
import type Money from 'js-money';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getContractById.graphql';

const defaultOptions = {} as const;
export type GetContractByIdQueryVariables = Types.Exact<{
	contractId: Types.Scalars['String'];
}>;

export type GetContractByIdQuery = {
	getContractById: {
		id: string;
		version: number;
		startDate: LocalDate;
		name: string;
		description: string;
		authorized: boolean;
		endDate?: LocalDate | null;
		caftAmount?: Money | null;
		fee?: {
			id: string;
			version: number;
			date: LocalDate;
			frequency: Types.FrequencyEnum;
			lastGeneration?: Date | null;
			feeLineItems: Array<{id: string; amount: Money; gst: Money; hst: Money; pst: Money; qst: Money; description: string}>;
		} | null;
		preAuthorizedPayment?: {
			id: string;
			version: number;
			frequency: Types.FrequencyEnum;
			date: LocalDate;
			authorizedLimit: Money;
			transitNumber: string;
			institutionNumber: string;
			bankAccountNumber: string;
			lastGeneration?: Date | null;
		} | null;
		authorizedBy?: {id: string; name: string} | null;
	};
};

export type GetContractByIdType = {
	id: string;
	version: number;
	startDate: LocalDate;
	name: string;
	description: string;
	authorized: boolean;
	endDate?: LocalDate | null;
	caftAmount?: Money | null;
	fee?: {
		id: string;
		version: number;
		date: LocalDate;
		frequency: Types.FrequencyEnum;
		lastGeneration?: Date | null;
		feeLineItems: Array<{id: string; amount: Money; gst: Money; hst: Money; pst: Money; qst: Money; description: string}>;
	} | null;
	preAuthorizedPayment?: {
		id: string;
		version: number;
		frequency: Types.FrequencyEnum;
		date: LocalDate;
		authorizedLimit: Money;
		transitNumber: string;
		institutionNumber: string;
		bankAccountNumber: string;
		lastGeneration?: Date | null;
	} | null;
	authorizedBy?: {id: string; name: string} | null;
};

/**
 * __useGetContractByIdQuery__
 *
 * To run a query within a React component, call `useGetContractByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractByIdQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractByIdQuery(baseOptions: Apollo.QueryHookOptions<GetContractByIdQuery, GetContractByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetContractByIdQuery, GetContractByIdQueryVariables>(Operations, options);
}
export function useGetContractByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractByIdQuery, GetContractByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetContractByIdQuery, GetContractByIdQueryVariables>(Operations, options);
}
export type GetContractByIdQueryHookResult = ReturnType<typeof useGetContractByIdQuery>;
export type GetContractByIdLazyQueryHookResult = ReturnType<typeof useGetContractByIdLazyQuery>;
export type GetContractByIdQueryResult = Apollo.QueryResult<GetContractByIdQuery, GetContractByIdQueryVariables>;
