import {useCan} from '@imperium/auth-client';
import {LocalDate} from '@js-joda/core';
import debug from 'debug';
import {List, Placeholder, Divider, CardGroup, Card, CardContent, CardHeader, CardDescription, Icon, Grid} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import {Permission} from '~core/graphql';
import CommunicationFeed from '../../../../communications/components/CommunicationFeed';
import AuthorizedRepresentativesCard from '../AuthorizedRepresentativesCard';
import {RelatedAccountsCard} from '../RelatedAccountsCard';
import {useGetPersonalAccountInfoInformationQuery} from './getPersonalAccountInfoInformation';

const d = debug('tacs.web.accounts.components.accounts.PersonalAccountInfo');

export function PersonalAccountInfo({accountInfoId}: {accountInfoId: string}) {
	const [readAllAccount] = useCan([Permission.ReadAllAccount]);
	const {data, loading, error} = useGetPersonalAccountInfoInformationQuery({
		variables: {id: accountInfoId},
	});

	if (error) throw error;
	if (loading || !data?.getPersonalAccountByAccountInfoId)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);

	const {accountInfo} = data.getPersonalAccountByAccountInfoId;

	return (
		<>
			<CardGroup>
				<Card>
					<CardContent>
						<CardHeader>
							<Icon name="clipboard" />
							Details
						</CardHeader>
						<Divider />
						<CardDescription>
							<List>
								<List.Item>
									<ColoredSpan labelText="Name" contentText={accountInfo?.name} copyTextOnClick />
								</List.Item>
								<List.Item />
								<List.Item>
									<ColoredSpan labelText="Manager" contentText={accountInfo?.manager?.name} copyTextOnClick />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Default Agent" contentText={accountInfo?.defaultAgent?.name} copyTextOnClick />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Status" contentText={accountInfo.archived ? 'Archived' : 'Active'} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Organization" contentText={accountInfo?.organization?.name} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Office" contentText={accountInfo?.office?.name} />
								</List.Item>
							</List>
						</CardDescription>
					</CardContent>
				</Card>
				<AuthorizedRepresentativesCard accountInfoId={accountInfoId} />
				{readAllAccount && <RelatedAccountsCard accountInfoId={accountInfoId} />}
			</CardGroup>
			{readAllAccount && (
				<Grid padded>
					<Grid.Row>
						<Grid.Column>
							<CommunicationFeed limit={5} startDate={LocalDate.now().minusYears(1)} endDate={LocalDate.now()} hideLoadMore />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</>
	);
}
