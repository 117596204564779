import {LocalDatePicker, TForm} from '@thx/controls';
import {formatDate, toDate, toLocalDate} from '@thx/date';
import debug from 'debug';
import {useCallback, useState} from 'react';
import {toast} from 'react-toastify';
import {Button, Divider, Dropdown, Form, Grid, Header, Input, Segment, Table} from 'semantic-ui-react';
import {array, number, object, string} from 'yup';
import type {SetCaftOptionsInput} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {CaftAccountFilterRow} from './CaftAccountFilterRow';
import {useGenerateScheduledPaymentsMutation} from './generateScheduledPayments';
import {useGetCaftOptionsQuery} from './getCaftOptions';

const d = debug('tacs.web.receivables.components.caftOptions.CaftOptions');

const caftOptionsValidationSchema = object().shape({
	filter: array().of(
		object()
			.shape({
				id: string().required(),
				name: string().required(),
				friendlyId: number().required(),
			})
			.required(),
	),
	schedPayDaysAhead: number().required(),
	paymentDaysAhead: number().required(),
	fileCreationNum: number().required(),
});

export function CaftOptions() {
	const throwError = useAsyncError();
	const {data, error, loading} = useGetCaftOptionsQuery();
	const handleSubmit = useCallback(() => {}, []);
	const [addId, setAddId] = useState();
	const [genSpNow, setGenSpNow] = useState(new Date());
	const [generateScheduledPayments] = useGenerateScheduledPaymentsMutation();

	if (loading || error || !data) return null;

	const initialValues: SetCaftOptionsInput = {
		filter: data.getCaftOptions.filter.map(v => v.id),
		schedPayDaysAhead: data.getCaftOptions.schedPayDaysAhead,
		paymentDaysAhead: data.getCaftOptions.paymentDaysAhead,
		fileCreationNum: data.getCaftOptions.fileCreationNum,
	};

	return (
		<>
			<TForm<SetCaftOptionsInput> initialValues={initialValues} onSubmit={handleSubmit}>
				{tFormProps => {
					const {values, setFieldValue, dirty} = tFormProps;
					return (
						<Grid divided relaxed>
							<Grid.Row>
								<Grid.Column width={3}>
									<Form>
										<Form.Field>
											<label>File creation number</label>
											<Input
												type="number"
												placeholder="File creation number"
												min={0}
												value={values.fileCreationNum}
												onChange={ev => setFieldValue('fileCreationNum', ev.target.valueAsNumber)}
											/>
										</Form.Field>
										<Form.Field>
											<label>Scheduled Payment days ahead</label>
											<Input
												type="number"
												placeholder="Scheduled payment days ahead"
												min={0}
												value={values.schedPayDaysAhead}
												onChange={ev => setFieldValue('schedPayDaysAhead', ev.target.valueAsNumber)}
											/>
										</Form.Field>
										<Form.Field>
											<label>Payment days ahead</label>
											<Input
												type="number"
												placeholder="Payment days ahead"
												min={0}
												value={values.paymentDaysAhead}
												onChange={ev => setFieldValue('paymentDaysAhead', ev.target.valueAsNumber)}
											/>
										</Form.Field>
									</Form>
								</Grid.Column>
								<Grid.Column width={9}>
									<Segment basic>
										<Header>CAFT account filter</Header>
										<Form>
											<Form.Field>
												<label>Add account:</label>
												<Form.Group>
													<Dropdown
														fluid
														search
														selection
														options={data?.getAllAccountInfo?.map(v => ({text: `${v.friendlyId} - ${v.name}`, value: v.id}))}
														onChange={(ev, h) => {
															setAddId(h.value as any);
														}}
													/>
													<Button
														primary
														attached="right"
														onClick={() => {
															if (addId && !values.filter.includes(addId)) {
																setFieldValue('filter', [...values.filter, addId]);
															}
														}}
													>
														Add
													</Button>
												</Form.Group>
											</Form.Field>
										</Form>
										<Table selectable compact>
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell>Account</Table.HeaderCell>
													<Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												{values.filter.map(id => (
													<CaftAccountFilterRow
														id={id}
														accounts={data?.getAllAccountInfo || []}
														key={id}
														removeAccount={idToRemove => {
															setFieldValue(
																'filter',
																values.filter.filter(i => i !== idToRemove),
															);
														}}
													/>
												))}
											</Table.Body>
										</Table>
									</Segment>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={12} textAlign="right">
									<span style={{marginRight: '1em'}}>{dirty && 'There are unsaved changes'}</span>
									<Button primary disabled={!dirty}>
										Save
									</Button>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					);
				}}
			</TForm>
			<Divider />
			<Segment>
				<Header>Tools</Header>
				<Form>
					<Form.Field inline>
						<label>Simulated today&apos;s date:</label>
						<Form.Input>
							<LocalDatePicker value={toLocalDate(genSpNow)} onChange={v => setGenSpNow(toDate(v || new Date()))} />
						</Form.Input>
					</Form.Field>
					<Button
						primary
						type="button"
						onClick={() => {
							generateScheduledPayments({variables: {now: genSpNow}})
								.then(() => {
									toast.success(`Generated scheduled payments for ${formatDate(genSpNow)}`);
								})
								.catch(throwError);
						}}
					>
						Generate Scheduled Payments
					</Button>
				</Form>
			</Segment>
		</>
	);
}
