import debug from 'debug';
import {useState} from 'react';
import {Button, Table} from 'semantic-ui-react';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import type {UnArray} from '~core/graphql';
import {routes} from '../../../accounts/routes';
import type {GetCaftOptionsQuery} from './getCaftOptions';

const d = debug('tacs.web.receivables.components.caftOptions.CaftAccountFilterRow');

interface CaftAccountFilterRowProps {
	id: string;
	accounts: Required<UnArray<GetCaftOptionsQuery['getAllAccountInfo']>>[];
	removeAccount: (accountId: string) => void;
}

export function CaftAccountFilterRow({id, accounts, removeAccount}: CaftAccountFilterRowProps) {
	const [isHovered, setHovered] = useState(false);

	const acct = accounts.find(q => q?.id === id);
	const name = acct ? `${acct.friendlyId} - ${acct.name}` : 'Not found in account list';
	return (
		<Table.Row onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
			<LinkedTableCell to={acct ? routes.to.account({accountInfoId: acct.id}) : ''}>{name}</LinkedTableCell>
			<Table.Cell textAlign="center">
				{isHovered && (
					<Button
						basic
						size="mini"
						icon="remove"
						style={{padding: '0.4em'}}
						onClick={() => {
							removeAccount(id);
						}}
					/>
				)}
			</Table.Cell>
		</Table.Row>
	);
}
