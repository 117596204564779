import type {ImperiumLayoutClientModule} from '@imperium/layout';
import type {ImperiumRouterClientModule} from '@imperium/router';
import {layout} from './layout';
import {routeProps} from './pages';

export function receivablesModule(): ImperiumLayoutClientModule & ImperiumRouterClientModule {
	return {
		name: 'Receivables',
		layout,
		routeProps,
	};
}
