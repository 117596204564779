import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getCaftOptions.graphql';

const defaultOptions = {} as const;
export type GetCaftOptionsQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetCaftOptionsQuery = {
	getCaftOptions: {
		schedPayDaysAhead: number;
		paymentDaysAhead: number;
		fileCreationNum: number;
		filter: Array<{id: string; name: string; friendlyId: number}>;
	};
	getAllAccountInfo?: Array<{id: string; name: string; friendlyId: number}> | null;
};

export type GetCaftOptionsType = {
	schedPayDaysAhead: number;
	paymentDaysAhead: number;
	fileCreationNum: number;
	filter: Array<{id: string; name: string; friendlyId: number}>;
};

/**
 * __useGetCaftOptionsQuery__
 *
 * To run a query within a React component, call `useGetCaftOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaftOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaftOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCaftOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCaftOptionsQuery, GetCaftOptionsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetCaftOptionsQuery, GetCaftOptionsQueryVariables>(Operations, options);
}
export function useGetCaftOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCaftOptionsQuery, GetCaftOptionsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetCaftOptionsQuery, GetCaftOptionsQueryVariables>(Operations, options);
}
export type GetCaftOptionsQueryHookResult = ReturnType<typeof useGetCaftOptionsQuery>;
export type GetCaftOptionsLazyQueryHookResult = ReturnType<typeof useGetCaftOptionsLazyQuery>;
export type GetCaftOptionsQueryResult = Apollo.QueryResult<GetCaftOptionsQuery, GetCaftOptionsQueryVariables>;
