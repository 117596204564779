import {useAuthenticatedState, useCan} from '@imperium/auth-client';
import {LocalDate} from '@js-joda/core';
import {formatDate} from '@thx/date';
import debug from 'debug';
import {round} from 'lodash-es';
import React from 'react';
import {Card, CardContent, CardDescription, CardGroup, CardHeader, Divider, Grid, Icon, Label, List, Placeholder, Progress} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import {DirectorOfficeEnum, Permission} from '~core/graphql';
import {getName} from '~lib/getName';
import CommunicationFeed from '../../../../communications/components/CommunicationFeed';
import {AccountRatingLabel} from '../../../../tasks/components/accountRating/AccountRatingLabel';
import AuthorizedRepresentativesCard from '../AuthorizedRepresentativesCard';
import EmployeesCard from '../EmployeesCard';
import {RelatedAccountsCard} from '../RelatedAccountsCard';
import {useGetCorporateAccountInformationQuery} from './getCorporateAccountInformation';

const d = debug('tacs.web.accounts.components.accounts.CorporateAccountInfo');

interface CorporateAccountInfoType {
	directors: {office: string; contact: {givenName: string; surname: string; preferredName?: string | null}}[];
}

export function CorporateAccountInfo({accountInfoId}: {accountInfoId: string}) {
	const {id} = useAuthenticatedState();
	const {data, loading, error} = useGetCorporateAccountInformationQuery({variables: {id: accountInfoId, currentlyLoggedInUserId: id || ''}});
	const [readAllAccount] = useCan([Permission.ReadAllAccount]);
	const [viewAdminPayrollAccount] = useCan({permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId}});

	if (error) throw error;
	if (loading || !data?.getCorporateAccountByAccountInfoId)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	const {directors, accountInfo, yearEnd, gstFilingFrequency, communicationCode, incorporationDate} = data.getCorporateAccountByAccountInfoId;

	const communicationEmail = communicationCode
		? `mymc-${accountInfo.friendlyId.toString().padStart(4, '0')}${communicationCode}@makingyourmilescount.com`
		: undefined;

	function getDirectorByOffice(office: DirectorOfficeEnum, rectors: CorporateAccountInfoType['directors'] | null | undefined) {
		return rectors?.find(dir => dir?.office === office);
	}

	let president;
	let vicePresident;
	if (directors?.length) {
		president = getDirectorByOffice(DirectorOfficeEnum.President, directors);
		vicePresident = getDirectorByOffice(DirectorOfficeEnum.VicePresident, directors);
	}

	function calculateMonths(days: number) {
		return round(days / 30);
	}

	function displayMonths(days: number) {
		const months = calculateMonths(days);
		if (months <= 1) {
			return 'month.';
		}
		return `${months} months.`;
	}

	return (
		<>
			<CardGroup>
				<Card>
					{readAllAccount && data.getCorporateAccountByAccountInfoId.accountRating?.id && (
						<AccountRatingLabel accountRating={data.getCorporateAccountByAccountInfoId.accountRating} corner />
					)}
					<CardContent>
						<CardHeader>
							<Icon name="clipboard" />
							Overview
						</CardHeader>
						<Divider />
						<CardDescription>
							<List>
								<List.Item>
									<ColoredSpan labelText="President" contentText={president ? getName(president?.contact) : ''} copyTextOnClick />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Vice-President" contentText={vicePresident ? getName(vicePresident?.contact) : ''} copyTextOnClick />
								</List.Item>

								{(data.getAccountCarriers?.length || 0) === 1 && (
									<List.Item>
										<ColoredSpan labelText="Carrier" contentText={data.getAccountCarriers?.[0]?.vendor.name} />
									</List.Item>
								)}
								{(data.getAccountCarriers?.length || 0) > 1 && (
									<List.Item>
										<ColoredSpan labelText="Carriers" />
										<List>
											{data.getAccountCarriers?.map(carrier => {
												return (
													<List.Item key={carrier.vendor.id}>
														<ColoredSpan contentText={`${carrier.vendor.name} (${carrier.year})`} copyTextOnClick />
													</List.Item>
												);
											})}
										</List>
									</List.Item>
								)}
							</List>
						</CardDescription>
					</CardContent>
					{readAllAccount && (
						<CardContent>
							<CardDescription>
								<List>
									<List.Item>
										<Progress
											percent={(calculateMonths(data.getAverageDaysToReceiveDocuments) / 6) * 100}
											success={
												calculateMonths(data.getAverageDaysToReceiveDocuments) === 0 ||
												calculateMonths(data.getAverageDaysToReceiveDocuments) === 1 ||
												calculateMonths(data.getAverageDaysToReceiveDocuments) === 2 ||
												calculateMonths(data.getAverageDaysToReceiveDocuments) === 3
											}
											warning={
												calculateMonths(data.getAverageDaysToReceiveDocuments) === 4 || calculateMonths(data.getAverageDaysToReceiveDocuments) === 5
											}
											error={calculateMonths(data.getAverageDaysToReceiveDocuments) >= 6}
										>
											Paperwork is received every {displayMonths(data.getAverageDaysToReceiveDocuments)}
										</Progress>
									</List.Item>
								</List>
							</CardDescription>
						</CardContent>
					)}
					<CardContent extra>
						<Label
							color={accountInfo.archived ? 'red' : 'green'}
							icon={accountInfo.archived ? 'archive' : 'folder open'}
							content={accountInfo.archived ? 'Status: Archived' : 'Status: Active'}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<CardHeader>
							<Icon name="clipboard list" />
							Details
						</CardHeader>
						<Divider />
						<CardDescription>
							<List>
								<List.Item>
									<ColoredSpan labelText="Business Number" contentText={data?.getCorporateAccountByAccountInfoId.businessNumber} copyTextOnClick />
								</List.Item>
								<List.Item>
									<ColoredSpan
										labelText="Address"
										copyTextOnClick
										contentText={[
											data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.lineOne,
											data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.lineTwo,
											data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.city,
											data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.province,
											data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.postalCode,
										]
											.filter(value => value)
											.join(', ')}
									/>
								</List.Item>
								<List.Item>
									{communicationEmail && <ColoredSpan labelText="Communication Email" contentText={communicationEmail} copyTextOnClick />}
								</List.Item>
								<List.Item>
									<ColoredSpan
										labelText="Incorporation Date"
										copyTextOnClick
										contentText={formatDate(incorporationDate, {date: true, type: 'long', format: 'MMMM dd, yyyy'})}
									/>
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Year End" contentText={formatDate(yearEnd, {date: true, type: 'long', format: 'MMMM dd'})} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="GST Filing Frequency" contentText={gstFilingFrequency || ''} />
								</List.Item>
								<List.Item />
								<List.Item>
									<ColoredSpan labelText="Manager" contentText={accountInfo?.manager?.name} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Default Agent" contentText={accountInfo?.defaultAgent?.name} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Organization" contentText={accountInfo?.organization?.name} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Office" contentText={accountInfo?.office?.name} />
								</List.Item>
							</List>
						</CardDescription>
					</CardContent>
				</Card>
				<AuthorizedRepresentativesCard accountInfoId={accountInfoId} />
				<EmployeesCard accountInfoId={accountInfoId} />
				{readAllAccount && <RelatedAccountsCard accountInfoId={accountInfoId} />}
			</CardGroup>
			{readAllAccount && viewAdminPayrollAccount && (
				<Grid padded>
					<Grid.Row>
						<Grid.Column>
							<CommunicationFeed limit={5} startDate={LocalDate.now().minusYears(1)} endDate={LocalDate.now()} hideLoadMore />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</>
	);
}
